<template>
  <div class="my-map" id="my-map" ref="maps">
    <div class="fly" @click="goFly"></div>
  </div>
</template>

<script>
const { Cesium } = window;
import { getAllMarkDataApi } from "@/request/api.js";
import { mapState } from "vuex";
export default {
  name: "cesiumMap",
  props: {
    cameraList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      viewer: null,
      scene: null,
      mapDataList: [],
      lineList: [],
      equipmentList: [],
      positionMap: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.loadingModel();
    }, 1000);
  },
  created() {
    this.positionMap = this.farmInfo.position.split(",");
    this.handMap();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
  beforeDestroy() {
    window.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
  },
  methods: {
    goFly() {
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          this.positionMap[0],
          this.positionMap[1],
          1000
        ),
        orientation: {
          heading: 0,
          pitch: Cesium.Math.toRadians(-70.5 || -Cesium.Math.PI_OVER_FOUR),
          roll: Cesium.Math.toRadians(360 || 0),
        },
        duration: 5,
      });
    },
    async handMap() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code !== 0) return;
      this.mapDataList = results;
      this.mapDataList.forEach((item) => {
        if (item.group == "地块") {
          item.items.forEach((items) => {
            items.mark_data = JSON.parse(items.mark_data);
            items.mark_data = items.mark_data.flat(Infinity);
          });
          this.lineList = item.items;
        } else {
          item.items.forEach((items) => {
            items.mark_data = items.mark_data.split(",");
            items.mark_data[0] = +items.mark_data[0];
            items.mark_data[1] = +items.mark_data[1];
          });
          this.equipmentList = this.mapDataList.filter((item) => {
            return item.group !== "地块";
          });
        }
      });
      this.init();
    },
    loadingModel() {
      this.equipmentList.forEach((item) => {
        item.items.forEach((items) => {
          if (!items.mark_data[0] || !items.mark_data[1]) return;
          this.batchAddModelAsPrimitive(
            window.viewer,
            items.mark_data,
            items.icon,
            10.0,
            items.title,
            items.id
          );
        });
      });
    },
    init() {
      Cesium.Ion.defaultAccessToken = this.$cesiumKey;

      window.viewer = new Cesium.Viewer("my-map", {
        homeButton: false,
        sceneModePicker: false,
        requestRenderMode: false,
        baseLayerPicker: false,
        animation: false,
        infoBox: false,
        selectionIndicator: false,
        geocoder: false,
        timeline: false,
        fullscreenButton: false,
        showRenderLoopErrors: false,
        navigationHelpButton: false,
        scene3DOnly: false,
        skyAtmosphere: false,
        useBrowserRecommendedResolution: false,
        terrainProvider: new Cesium.createWorldTerrain({
          requestWaterMask: true,
          requestVertexNormals: true,
        }),
      });

      window.viewer.scene.globe.preloadAncestors = true;

      // 调试用 右上角 开始
      window.viewer.scene.debugShowFramesPerSecond = true;
      // 调试用 结束

      window.viewer.scene.postProcessStages.fxaa.enabled = true;
      window.viewer.scene.globe.depthTestAgainstTerrain = true;

      window.viewer.scene.globe.maximumScreenSpaceError = 1;
      window.viewer.scene.light = new Cesium.DirectionalLight({
        direction: new Cesium.Cartesian3(0.354925, -0.890918, -0.283358),
      });

      // 限制浏览范围开始
      window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 800; //限制地图浏览范围的观看最低高度
      // window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 4000; //限制地图浏览范围的观看最高高度

      // 添加多边形 开始
      // 清除所有
      // window.viewer.entities.removeAll();
      this.lineList.forEach((items) => {
        window.viewer.entities.add({
          polyline: {
            width: 8.0,
            positions: Cesium.Cartesian3.fromDegreesArray(items.mark_data),
            clampToGround: true,
            zIndex: 9999,
            material: Cesium.Color.fromRandom(),
          },
        });
      });
      // 开启后camera失效
      // window.viewer.zoomTo(window.viewer.entities);
      // 添加多边形 结束
      // 地图加载视角和区域 开始
      window.viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(
          this.positionMap[0],
          this.positionMap[1],
          1000
        ),
        orientation: {
          heading: 0.0,
          pitch: -Cesium.Math.PI_OVER_TWO,
          roll: 0.0,
        },
      });
      // 地图加载视角和区域 结束
      // 加载大量模型开始
      // this.loadingModel();
      // 加载大量模型结束
      // 事件开始·

      window.handler = new Cesium.ScreenSpaceEventHandler(
        window.viewer.scene.canvas
      );

      window.handler.setInputAction((e) => {
        var pick = window.viewer.scene.pick(e.position);
        if (Cesium.defined(pick)) {
          this.$notify({
            message: pick.id,
            type: "success",
          });
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      // 事件结束
    },
    // 加载模型 primitive
    async batchAddModelAsPrimitive(
      viewer,
      positionArray,
      modelUrl,
      scale,
      title,
      id,
      clampToGround = true
    ) {
      /**
       * 批量添加模型
       * @param {*} viewer cesium.viewer
       * @param {*} positionArray 位置信息数组， 位置信息结构：{position:[lng , lat , height], hpr:[h,p,r]}
       * @param {*} modelUrl 模型的位置
       * @param {*} scale 模型缩放大小
       * @param {*} clampToGround 是否贴地，默认为true
       */
      let height;
      if (clampToGround) {
        let cartographic = Cesium.Cartographic.fromDegrees(
          positionArray[0],
          positionArray[1]
        );
        let terrainProvider = Cesium.createWorldTerrain();
        let updatedCartographics = await Cesium.sampleTerrainMostDetailed(
          terrainProvider,
          [cartographic]
        );
        height = updatedCartographics[0].height;
      } else {
        height = positionArray[2];
      }
      let cartesian3 = Cesium.Cartesian3.fromDegrees(
        positionArray[0],
        positionArray[1],
        height
      );
      let modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(cartesian3);
      window.viewer.scene.primitives.add(
        Cesium.Model.fromGltf({
          url: `${modelUrl}.glb`,
          id: { title, id },
          modelMatrix,
          scale,
          minimumPixelSize: 1,
          maximumScale: 2,
          allowPicking: true,
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          scene: window.viewer.scene,
        })
      );
    },
  },
};
</script>

<style lang="less" scoped>
.my-map {
  width: 100vw;
  height: 100vh;
  position: relative;
  // logo信息
  /deep/.cesium-viewer-bottom {
    display: none;
    color: rgb(112, 222, 33);
  }
  .fly {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    background: url("../assets/image/fly.png") center center no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: 999;
    bottom: 180px;
    right: 80px;
  }
}
</style>
